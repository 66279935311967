<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >

    <el-form-item label="Единица измерения" prop="unit_id">
      <element-scale-unit-select
        v-model="scale.unit_id"
        :current-unit="scale.unit"
      ></element-scale-unit-select>
    </el-form-item>

    <el-form-item label="Значения">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <el-form-item prop="target">
            <el-input v-number-format v-model="scale.target" :disabled="disabled">
              <template slot="prepend">Цель</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="fact">
            <el-input v-number-format v-model="scale.fact" :disabled="disabled">
              <template slot="prepend">Факт</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item prop="bonus">
            <el-input v-number-format v-model="scale.bonus" :disabled="disabled">
              <template slot="prepend">Процент исполнения</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {mapGetters} from "vuex";
import ElementScaleUnitSelect from "@/components/filters/elements/ElementScaleUnitSelect.vue";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {ElementScaleUnitSelect},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    })
  },

  data() {
    return {
      scale:{
        target: null,
        fact: null,
        bonus: null,
      },

      rules: {}


    }
  },

  methods: {

  }
}
</script>

<style>

</style>